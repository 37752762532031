import startCase from 'lodash/startCase';
import uid from 'lodash/uniqueId';
import React, { useContext, useEffect } from 'react';

import { EnterpriseParentContext, ProjectContext } from '@core/context';
import useRoute from '@core/hooks/useRoute';
import classy from '@core/utils/classy';

import * as blocks from './Blocks';
import c from './style.module.scss';

// eslint-disable-next-line import/namespace
const getBlock = ({ type }) => blocks?.[`${startCase(type).replace(' ', '')}Block`];

const LandingPage = props => {
  const { state, loading } = useRoute(props);
  const { project } = useContext(ProjectContext);
  const { landing_bottom: sections = [] } = project;
  const enterprise = useContext(EnterpriseParentContext);

  useEffect(() => {
    const $body = document.getElementsByTagName('body')[0];
    const cname = 'hub-is-home';
    $body.classList.add(cname);
    return () => $body.classList.remove(cname);
  }, []);

  /* Render the GLP
   */
  if (enterprise.isParent) {
    return <div dangerouslySetInnerHTML={{ __html: enterprise.lpMarkup }} />;
  }

  /* Render the LP CMS Template
   */
  return (
    !loading && (
      <main className={classy(c.LandingWrap, 'rm-LandingPage')} id="content">
        {sections.map(s => {
          const BlockType = getBlock(s);
          const alignment = s?.alignment ? c[`LandingBlock_align=${s.alignment}`] : '';
          return BlockType ? <BlockType key={uid()} className={alignment} {...state} {...s} /> : null;
        })}
      </main>
    )
  );
};

export default LandingPage;

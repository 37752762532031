import { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import useSWR from 'swr';

import { BaseUrlContext, ProjectContext } from '@core/context';
import useProjectUrl from '@core/hooks/useProjectUrl';
import { useSuperHubStore } from '@core/store';

const NOT_FOUND_ROUTE = '/404';

async function fetcher(url) {
  const res = await fetch(url, {
    redirect: 'follow',
    headers: {
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  const contentType = res.headers.get('Content-Type');
  const isJsonResponse = ['application/json', 'application/problem+json'].some(type => contentType?.includes(type));
  const json = isJsonResponse ? await res.json() : undefined;
  const redirectedPath = res.redirected ? new URL(res.url).pathname : undefined;

  // Reject the fetch with a general error object if the response was
  // unsuccessful with any status code outside the range of 200-299.
  if (!res.ok) {
    const error = new Error(`An error occurred while fetching "${url}"`);
    error.status = res.status;
    error.statusText = res.statusText;
    error.url = url;
    throw error;
  }

  return {
    redirected: res.redirected,
    redirectedPath,
    json,
  };
}

/**
 * A simple hook that manages a piece of state by `fetch`ing
 * some JSON from the matching URL on client-side navigation.
 *
 * @param {Object} initialData The default shape of the state.
 */
export default function useRouteData(initialData = {}) {
  const url = useProjectUrl();
  const routerMatch = useRouteMatch();
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const [isInitialRoute, setIsInitialRoute] = useState(!!Object.keys(initialData).length);
  const { project } = useContext(ProjectContext);
  const baseURL = useContext(BaseUrlContext);
  const isSuperHub = useSuperHubStore(s => s.isSuperHub);

  // Construct the route endpoint.
  const endpoint = url || (typeof window !== 'undefined' && window.location) || '/';

  // Determine whether we're coming from a legacy reference URL using the old hashed format,
  // e.g. /reference#getcategories
  // (but NOT /reference/categories#getcategories, see above)
  // If we are, we avoid using the `initialData` to populate the page.
  // @note: SuperHub uses hash routing, so we need to allow hashes for SuperHub users!
  const legacyUrl = !isSuperHub && location.pathname === '/reference' && location.hash.startsWith('#');

  // Keep track of when we're loading a route that matches the initial route
  // that we have server-side provided data with. We can then use this flag to
  // conditionally provide SWR with fallback data versus none at all.
  useEffect(() => {
    const endpointPath = endpoint.split(/(?:\?|&)json=on/)[0];
    setIsInitialRoute([initialData.reqUrl, initialData.slugUrl].includes(endpointPath));
  }, [endpoint, initialData.reqUrl, initialData.slugUrl]);

  /**
   * If we've come from a legacy reference URL that uses the old hashed format,
   * e.g. /reference#getcategories
   * we need to update the URL when we've successfully retrieved the correct data.
   * Unfortunately we can't redirect URLs for legacy split reference sections,
   * since this will cause current anchor links to break!
   * e.g. /reference/categories#getcategories should be ignored
   * See https://linear.app/readme-io/issue/RM-1546#comment-2bdcbd64
   */
  useEffect(() => {
    if (legacyUrl) {
      const updatedSlug = location.hash.replace('#', '');
      history.replace(`/reference/${updatedSlug}`);
    }
  }, [history, legacyUrl, location.hash]);

  const {
    data: { json, redirected, redirectedPath } = {},
    error,
    mutate,
    isValidating,
  } = useSWR(isInitialRoute ? null : endpoint, fetcher, {
    // Only provide SWR with fallback data when loading the initial route. This
    // ensures that the "loading" state page is clear of content when loading
    // new routes.
    fallbackData: isInitialRoute && !legacyUrl ? { json: initialData, redirected: false } : undefined,
    revalidateOnFocus: false,
  });
  const is2xx = !!json && !redirected;
  const redirectExternal = !(redirectedPath?.startsWith(baseURL) ?? true);

  /**
   * This "unknown" error is `fetch` throwing internally. The main known case when that happens is when the user's auth expires.
   * The reason fetch ends up throwing is because the server responds with a 302 redirect to the login page in the dash app but we are on the hub app.
   * I.e. this redirect violates the CORS policy because the `mode` option in the fetch request is set to "cors" and the `credentials` option is set to "include".
   * The code that performs this redirect is in the `accesscontrol` middleware. Generally it's used to redirect users to the login page when they have no active session.
   */
  const isUnknownError = !!error && !error.status;
  const isUnauthorizedError = error?.status === 401;
  const isInternalDocs = !!project?.internal;
  useEffect(() => {
    /**
     * This handles the case where hub users' auth to an internal project expires; it doesn't cover the case when:
     * - auth expires on a public project, see AUTH-471.
     * - a project is made internal while a user is viewing; the project info becomes stale and has no way of being updated.
     */
    if (isInternalDocs && (isUnauthorizedError || isUnknownError)) {
      const isSiteWidePassword = project.internal === 'password';
      const redirectQParam = `redirect=${window.location.pathname}`;
      // Avoid using the router here because the login page is outside of its scope.
      window.location.assign(isSiteWidePassword ? `/password?${redirectQParam}` : `/login?${redirectQParam}`);
    }
  }, [isUnauthorizedError, isUnknownError, isInternalDocs, project.internal]);

  // When navigating to a route that returns a 404 error, force a refresh so the
  // react component can rehydrate with is404 set to true.
  useEffect(() => {
    /* error.status is set server-side on initial load; json.is404
     * refers to the results of the ?json=on fetch from useRoute
     */
    if ((error?.status === 404 || json?.is404) && !isInitialRoute) {
      history.replace(NOT_FOUND_ROUTE);
    }
  }, [error, history, isInitialRoute, json?.is404]);

  /**
   * Attempt to emulate the Angular $.scope data
   * that we normally pass to the pageLoad event
   * as best we could...
   */
  useEffect(() => {
    if (is2xx && typeof $ === 'function') {
      const meta = json?.meta;
      $(window).trigger('pageLoad', {
        meta,
        params,
        opts: { reactApp: 'on', ...routerMatch },
        hash: window.location.hash,
        name: meta?.type,
      });
    }
    /**
     * Sadly, json.meta (and the rest of the `params` and `routerMatch` objects)
     * cannot be included as deps here because they'll trigger more runs after a page loads
     * and we only want this hook to fire *once* after a page loads!
     */
  }, [is2xx, params?.slug, routerMatch?.url]); // eslint-disable-line react-hooks/exhaustive-deps

  let redirect = redirectedPath;
  if (redirect && redirected) {
    if (!redirectExternal) {
      /* If we have a child subdomain and it matches the project we're looking
       * at, we need to remove it because we append the child subdomain to the
       * baseURL in packages/react/src/Hub/web. Removing the child subdomain
       * here prevents situations where we have a duplicate subdomain append
       * to the baseURL.
       */
      redirect = redirect.replace(`${project.subdomain}/`, '');
    } else {
      /* If it does not match, we need to issue a full browser-level redirect
       * so that our custom Redirects feature works across enterprise children
       * with different subdomains. (See: #11725)
       */
      window.location.replace(redirect);
      if (json?.doc) json.doc = {}; // force empty the doc to avoid content flash
    }
  }

  return {
    data: json,
    loading: redirectExternal || (!error && !json),
    redirected: redirected && !redirectExternal,
    redirectedPath: redirect,
    error,
    mutate,
    validating: isValidating,
  };
}
